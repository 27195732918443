import React, { useState } from "react";
import { useGlobalContext } from "../Config";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

const Tarifa = () => {
  const { baseUrl } = useGlobalContext();
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
  const [anioSeleccionado, setAnioSeleccionado] = useState(null);
  const [tarifas, setTarifas] = useState(null);
  const [historial, setHistorial] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingHistorial, setLoadingHistorial] = useState(false);
  const [tipoHistorial, setTipoHistorial] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [tarifaActualizada, setTarifaActualizada] = useState({
    tipo: "",
    valor: "",
  });

  const idUsuario = sessionStorage.getItem("id");

  const empresas = [
    { label: "ALTAMIRA", value: 1 },
    { label: "LA SIEMBRA", value: 2 },
  ];

  const anios = [
    { label: "2023", value: 2023 },
    { label: "2024", value: 2024 },
  ];

  const buscarTarifas = async () => {
    if (!empresaSeleccionada) {
      alert("Por favor selecciona una empresa.");
      return;
    }

    setIsLoading(true);
    const endpoint = `${baseUrl}buscarTarifasActivasPorEmpresa?idEmpresa=${empresaSeleccionada}`;

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      });

      const data = await response.json();

      if (!response.ok || !data.respuesta) {
        throw new Error(data.mensaje || "Error al buscar tarifas.");
      }

      const tarifasTraducidas = data.tarifas.map((tarifa) => ({
        ...tarifa,
        tipoDescripcion: tarifa.idTipo === 1 ? "AGUA" : "ELECTRICIDAD",
      }));

      // Ordenar siempre: Agua primero, Electricidad segundo
      tarifasTraducidas.sort((a, b) => a.idTipo - b.idTipo);

      setTarifas(tarifasTraducidas);
    } catch (error) {
      alert("Error al buscar tarifas: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const verHistorial = async (tipo) => {
    if (!empresaSeleccionada) {
      alert("Por favor selecciona una empresa.");
      return;
    }

    setLoadingHistorial(true);

    const nombreHistorial = tipo === 1 ? "Agua" : "Electricidad";
    setTipoHistorial(nombreHistorial);

    const endpoint = `${baseUrl}buscarTarifasPorTipoOEmpresa?idEmpresa=${empresaSeleccionada}&idTipo=${tipo}`;
    try {
      const response = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      });

      const data = await response.json();

      if (!response.ok || !data.respuesta) {
        throw new Error(data.mensaje || "Error al buscar el historial.");
      }

      setHistorial(data.tarifas);
    } catch (error) {
      alert("Error al buscar el historial: " + error.message);
    } finally {
      setLoadingHistorial(false);
    }
  };

  const abrirDialogo = (tipo) => {
    setTarifaActualizada({ tipo, valor: "" });
    setShowDialog(true);
  };

  const actualizarTarifa = async () => {
    if (!tarifaActualizada.valor || isNaN(tarifaActualizada.valor)) {
      alert("Por favor ingresa un valor válido.");
      return;
    }

    // Determinar idTipo según tarifaActualizada.tipo
    const idTipo = tarifaActualizada.tipo === "AGUA" ? 1 : 2;

    try {
      // Usamos el endpoint dado con parámetros por query string
      const response = await fetch(
        `${baseUrl}insertarTarifa?idTipo=${idTipo}&idEmpresa=${empresaSeleccionada}&tarifa=${tarifaActualizada.valor}&idUsuario=${idUsuario}`,
        {
          method: "POST",
          credentials: "include",
          redirect: "follow",
        }
      );

      const data = await response.json();
      if (data.respuesta) {
        alert("Tarifa actualizada con éxito.");
        buscarTarifas(); // Refresca las tarifas después de la actualización
        setShowDialog(false);
      } else {
        alert(`Error: ${data.mensaje}`);
      }
    } catch (error) {
      console.error("Error al actualizar tarifa:", error);
      alert("Ocurrió un error al actualizar la tarifa.");
    }
  };

  return (
    <div className="col-12 md:col-12">
      <div
        style={{
          padding: "20px",
          backgroundColor: "#f8f9fa",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h4 style={{ margin: 0, color: "#495057" }}>Gestión de Tarifas</h4>
        </div>

        <div
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
          }}
        >
          <h5
            style={{
              borderBottom: "2px solid #ced4da",
              paddingBottom: "10px",
              marginBottom: "20px",
              color: "#495057",
            }}
          >
            Seleccione parámetros:
          </h5>
          <div className="grid" style={{ marginBottom: "20px" }}>
            <div className="col-12" style={{ paddingRight: "10px" }}>
              <label htmlFor="empresa" style={{ fontWeight: "bold" }}>
                Empresa:
              </label>
              <Dropdown
                id="empresa"
                value={empresaSeleccionada}
                options={empresas}
                onChange={(e) => {
                  setEmpresaSeleccionada(e.value);
                  setHistorial([]); // Vaciar el historial al cambiar de empresa
                }}
                placeholder="Seleccione una empresa"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ced4da",
                  marginBottom: "15px",
                }}
              />
            </div>
          </div>

          <Button
            label="Buscar"
            icon="pi pi-search"
            onClick={buscarTarifas}
            style={{
              backgroundColor: "#007bff",
              borderColor: "#007bff",
              color: "#fff",
              padding: "10px 20px",
              fontSize: "14px",
              borderRadius: "4px",
              display: "block",
              margin: "auto",
            }}
          />
        </div>
      </div>

      {isLoading && (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <ProgressSpinner />
        </div>
      )}

      {tarifas && (
        <div
          style={{
            padding: "20px",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        >
          <h5
            style={{
              borderBottom: "2px solid #ced4da",
              paddingBottom: "10px",
              marginBottom: "20px",
              color: "#495057",
            }}
          >
            Tarifas Actuales:
          </h5>

          <div className="grid">
            {tarifas.map((tarifa) => (
              <div className="col-12 md:col-6" key={tarifa.id}>
                <Card
                  title={`Tarifa ${tarifa.tipoDescripcion}`}
                  style={{ marginBottom: "20px" }}
                >
                  <p>
                    <strong>Valor:</strong> ${tarifa.tarifa}
                  </p>
                  <p>
                    <strong>Fecha Modificación:</strong>{" "}
                    {tarifa.fechaModificacion}
                  </p>
                  <Button
                    label="Actualizar"
                    onClick={() => abrirDialogo(tarifa.tipoDescripcion)}
                    style={{ backgroundColor: "#ffc107", color: "#fff" }}
                  />
                </Card>
              </div>
            ))}
          </div>

          <div className="grid" style={{ marginTop: "20px" }}>
            <div className="col-6" style={{ textAlign: "center" }}>
              <Button
                label="Ver Historial Agua"
                className="p-button-outlined"
                onClick={() => verHistorial(1)}
                style={{
                  backgroundColor: "#17a2b8",
                  color: "#fff",
                  padding: "10px 20px",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div className="col-6" style={{ textAlign: "center" }}>
              <Button
                label="Ver Historial Electricidad"
                className="p-button-outlined"
                onClick={() => verHistorial(2)}
                style={{
                  backgroundColor: "#17a2b8",
                  color: "#fff",
                  padding: "10px 20px",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
              />
            </div>
          </div>
        </div>
      )}

      {loadingHistorial && (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <ProgressSpinner />
        </div>
      )}

      {historial.length > 0 && (
        <div
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
            marginBottom: "20px",
          }}
        >
          <h5
            style={{
              borderBottom: "2px solid #ced4da",
              paddingBottom: "10px",
              marginBottom: "20px",
              color: "#495057",
            }}
          >
            Historial de Tarifas - {tipoHistorial}
          </h5>

          <DataTable
            value={historial}
            responsiveLayout="scroll"
            className="p-datatable-sm"
          >
            <Column field="tarifa" header="Valor" />
            <Column field="fechaModificacion" header="Fecha Modificación" />
          </DataTable>
        </div>
      )}

      <Dialog
        header={`Actualizar Tarifa - ${tarifaActualizada.tipo}`}
        visible={showDialog}
        style={{ width: "30vw" }}
        onHide={() => setShowDialog(false)}
        footer={
          <div>
            <Button
              label="Cancelar"
              onClick={() => setShowDialog(false)}
              style={{ backgroundColor: "#dc3545", color: "#fff" }}
            />
            <Button
              label="Actualizar"
              onClick={actualizarTarifa}
              style={{ backgroundColor: "#28a745", color: "#fff" }}
            />
          </div>
        }
      >
        <div style={{ marginBottom: "20px" }}>
          <label htmlFor="nuevaTarifa" style={{ fontWeight: "bold" }}>
            Nueva Tarifa:
          </label>
          <InputText
            id="nuevaTarifa"
            value={tarifaActualizada.valor}
            onChange={(e) =>
              setTarifaActualizada({
                ...tarifaActualizada,
                valor: e.target.value,
              })
            }
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ced4da",
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default Tarifa;
