import React, { useState } from "react";
import { useGlobalContext } from "../Config";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS } from "chart.js";
import { InputText } from "primereact/inputtext";
ChartJS.register(ChartDataLabels);

const Consumos = () => {
  const { baseUrl } = useGlobalContext();
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
  const [anioSeleccionado, setAnioSeleccionado] = useState(null);
  const [mesSeleccionado, setMesSeleccionado] = useState(null);
  const [tipoSeleccionado, setTipoSeleccionado] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [datosMedicion, setDatosMedicion] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [filtroCliente, setFiltroCliente] = useState("");

  const empresas = [
    { label: "ALTAMIRA", value: "ALTAMIRA" },
    { label: "LA SIEMBRA", value: "LA SIEMBRA" },
  ];

  const tipos = [
    { label: "Agua", value: "AGUA" },
    { label: "Electricidad", value: "ELECTRICIDAD" },
  ];

  const meses = [
    { label: "Enero", value: "01" },
    { label: "Febrero", value: "02" },
    { label: "Marzo", value: "03" },
    { label: "Abril", value: "04" },
    { label: "Mayo", value: "05" },
    { label: "Junio", value: "06" },
    { label: "Julio", value: "07" },
    { label: "Agosto", value: "08" },
    { label: "Septiembre", value: "09" },
    { label: "Octubre", value: "10" },
    { label: "Noviembre", value: "11" },
    { label: "Diciembre", value: "12" },
  ];

  const anios = [
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
  ];

  const buscarDatos = () => {
    if (
      !empresaSeleccionada ||
      !anioSeleccionado ||
      !mesSeleccionado ||
      !tipoSeleccionado
    ) {
      alert("Por favor selecciona todos los parámetros.");
      return;
    }

    setIsLoading(true);

    const periodo = `${anioSeleccionado}-${mesSeleccionado}`;
    const url = `${baseUrl}getDetalleMedicionesPorPeriodos?periodo=${periodo}&empresa=${empresaSeleccionada}&tipo=${tipoSeleccionado}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Error al obtener los datos");
      })
      .then((data) => {
        if (data.respuesta) {
          setDatosMedicion(data.detallePeriodos);

          // Calcular el consumo total
          const totalConsumo = data.detallePeriodos.reduce(
            (sum, item) => sum + item.consumo,
            0
          );

          // Configurar el gráfico para mostrar una sola barra
          setChartData({
            labels: [
              `Consumo Total (${data.detallePeriodos[0]?.unidadMedida || ""})`,
            ],
            datasets: [
              {
                label: "Consumo Total",
                backgroundColor: "#42A5F5",
                data: [totalConsumo],
              },
            ],
          });

          setChartOptions({
            responsive: true,
            plugins: {
              legend: {
                display: false,
                //position: "bottom",
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    return `Consumo: ${context.raw} ${
                      data.detallePeriodos[0]?.unidadMedida || ""
                    }`;
                  },
                },
              },
              datalabels: {
                anchor: "center", // Cambiar a 'end' para que las etiquetas queden fuera de la barra
                align: "start", // Alineación hacia afuera de la barra
                formatter: (value) => value.toFixed(2),
                font: {
                  weight: "bold",
                  size: 12,
                },
                color: "#000", // Color del texto
                offset: -10, // Añade un pequeño desplazamiento hacia afuera
              },
            },
            scales: {
              x: {
                ticks: {
                  callback: function (value, index) {
                    return this.getLabelForValue(value);
                  },
                },
              },
              y: {
                ticks: {
                  beginAtZero: true,
                },
              },
            },
          });
        } else {
          alert(data.mensaje || "No se encontraron datos");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("No se obtuvieron datos con los criterios proporcionados");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const datosFiltrados = datosMedicion.filter((item) =>
    item.nombreCliente.toLowerCase().includes(filtroCliente.toLowerCase())
  );

  return (
    <div className="grid">
      <div className="col-12 md:col-8">
        <div
          style={{
            padding: "20px",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h4 style={{ color: "#495057" }}>Estado de Mediciones</h4>
          <h5
            style={{
              borderBottom: "2px solid #ced4da",
              paddingBottom: "10px",
              marginBottom: "20px",
              color: "#495057",
            }}
          >
            Seleccione parámetros:
          </h5>
          <div className="grid" style={{ marginBottom: "20px" }}>
            <div className="col-12 md:col-6">
              <label htmlFor="empresa" style={{ fontWeight: "bold" }}>
                Empresa:
              </label>
              <Dropdown
                id="empresa"
                value={empresaSeleccionada}
                options={empresas}
                onChange={(e) => setEmpresaSeleccionada(e.value)}
                placeholder="Seleccione una empresa"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ced4da",
                  marginBottom: "15px",
                }}
              />
            </div>
            <div className="col-12 md:col-6">
              <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
                Tipo:
              </label>
              <Dropdown
                id="tipo"
                value={tipoSeleccionado}
                options={tipos}
                onChange={(e) => setTipoSeleccionado(e.value)}
                placeholder="Seleccione un tipo"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ced4da",
                  marginBottom: "15px",
                }}
              />
            </div>
            <div className="col-12 md:col-6">
              <label htmlFor="mes" style={{ fontWeight: "bold" }}>
                Mes:
              </label>
              <Dropdown
                id="mes"
                value={mesSeleccionado}
                options={meses}
                onChange={(e) => setMesSeleccionado(e.value)}
                placeholder="Seleccione un mes"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ced4da",
                  marginBottom: "15px",
                }}
              />
            </div>
            <div className="col-12 md:col-6">
              <label htmlFor="anio" style={{ fontWeight: "bold" }}>
                Año:
              </label>
              <Dropdown
                id="anio"
                value={anioSeleccionado}
                options={anios}
                onChange={(e) => setAnioSeleccionado(e.value)}
                placeholder="Seleccione un año"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ced4da",
                  marginBottom: "15px",
                }}
              />
            </div>
          </div>
          <Button
            label="Buscar"
            icon="pi pi-search"
            onClick={buscarDatos}
            style={{
              backgroundColor: "#007bff",
              borderColor: "#007bff",
              color: "#fff",
              padding: "10px 20px",
              fontSize: "14px",
              borderRadius: "4px",
              display: "block",
              margin: "auto",
            }}
          />
        </div>
      </div>

      {/* Gráfico */}
      <div className="col-12 md:col-4">
        <div
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Card title="Consumo Total Del Periodo">
            {chartData ? (
              <Chart type="bar" data={chartData} options={chartOptions} />
            ) : (
              <p style={{ textAlign: "center" }}>No hay datos para mostrar</p>
            )}
          </Card>
        </div>
      </div>

      {isLoading && (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <ProgressSpinner />
        </div>
      )}

      {datosMedicion.length > 0 && (
        <div
          className="col-12"
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
          }}
        >
          <h5
            style={{
              borderBottom: "2px solid #ced4da",
              paddingBottom: "10px",
              marginBottom: "20px",
              color: "#495057",
            }}
          >
            Resultados de la Búsqueda
          </h5>

          <InputText
            value={filtroCliente}
            onChange={(e) => setFiltroCliente(e.target.value)}
            placeholder="Buscar Por Cliente"
            style={{ marginBottom: "15px" }}
          />
          <DataTable value={datosFiltrados} responsiveLayout="scroll">
            <Column field="idDetalle" header="ID Detalle" />
            <Column field="nombreCliente" header="Cliente" />
            <Column field="domicilio" header="Domicilio" />
            {/*<Column field="idMedidor" header="ID Medidor" />*/}
            <Column field="periodoMedicion" header="Periodo" />
            <Column field="tipoMedidor" header="Tipo Medidor" />
            <Column field="unidadMedida" header="Unidad Medida" />
            <Column field="montoFinal" header="Monto Total" />
            <Column field="consumo" header="Consumo" />
          </DataTable>
        </div>
      )}
    </div>
  );
};

export default Consumos;
