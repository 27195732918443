import React, { useState } from "react";
import { useGlobalContext } from "../Config";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

const Consumos = () => {
  const { baseUrl } = useGlobalContext();
  const [cliente, setCliente] = useState("");
  const [domicilio, setDomicilio] = useState("");
  const [rangoTiempo, setRangoTiempo] = useState(3);
  const [tipo, setTipo] = useState(null); // Estado para el tipo de medidor
  const [resultados, setResultados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const rangos = [
    { label: "Últimos 3 meses", value: 3 },
    { label: "Últimos 6 meses", value: 6 },
    { label: "Últimos 12 meses", value: 12 },
    { label: "Últimos 24 meses", value: 24 },
  ];

  const tipos = [
    { label: "Agua", value: "Agua" },
    { label: "Electricidad", value: "Electricidad" },
  ];

  const buscarConsumos = async () => {
    if (!cliente && !domicilio && !rangoTiempo && !tipo) {
      alert("Por favor ingrese al menos un parámetro de búsqueda.");
      return;
    }

    setIsLoading(true);
    const params = new URLSearchParams();
    if (cliente) params.append("nombreCliente", cliente);
    if (domicilio) params.append("domicilio", domicilio);
    if (rangoTiempo) params.append("meses", rangoTiempo);
    if (tipo) params.append("tipo", tipo); // Agregar el tipo como parámetro

    const endpoint = `${baseUrl}getMedicionesPorClienteODomicilio?${params.toString()}`;

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      });

      const data = await response.json();
      if (!response.ok || !data.respuesta) {
        throw new Error(data.mensaje || "Error al buscar consumos.");
      }

      const uniqueResultados = Array.from(
        new Map(data.mediciones.map((item) => [item.idDetalle, item])).values()
      );

      setResultados(uniqueResultados); // Guardar los datos únicos en el estado
    } catch (error) {
      alert("Error al buscar consumos: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="col-12 md:col-12">
      <div
        style={{
          padding: "20px",
          backgroundColor: "#f8f9fa",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <h4 style={{ marginBottom: "20px", color: "#495057" }}>
          Búsqueda de Consumos
        </h4>

        <div
          className="grid"
          style={{ marginBottom: "20px", alignItems: "flex-end" }}
        >
          <div className="col-12 md:col-3">
            <label htmlFor="cliente" style={{ fontWeight: "bold" }}>
              Cliente:
            </label>
            <InputText
              id="cliente"
              value={cliente}
              onChange={(e) => setCliente(e.target.value)}
              placeholder="Ingrese nombre del cliente"
              style={{
                width: "100%",
                padding: "20px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
                marginBottom: "15px",
              }}
            />
          </div>

          <div className="col-12 md:col-3">
            <label htmlFor="domicilio" style={{ fontWeight: "bold" }}>
              Domicilio:
            </label>
            <InputText
              id="domicilio"
              value={domicilio}
              onChange={(e) => setDomicilio(e.target.value)}
              placeholder="Ingrese domicilio (Ej: Bodega 1)"
              style={{
                width: "100%",
                padding: "20px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
                marginBottom: "15px",
              }}
            />
          </div>

          <div className="col-12 md:col-3">
            <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
              Tipo de Medidor:
            </label>
            <Dropdown
              id="tipo"
              value={tipo}
              options={tipos}
              onChange={(e) => setTipo(e.value)}
              placeholder="Seleccione un tipo de medidor"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
                marginBottom: "15px",
              }}
            />
          </div>

          <div className="col-12 md:col-3">
            <label htmlFor="rango" style={{ fontWeight: "bold" }}>
              Rango de Tiempo:
            </label>
            <Dropdown
              id="rango"
              value={rangoTiempo}
              options={rangos}
              onChange={(e) => setRangoTiempo(e.value)}
              placeholder="Seleccione un rango de tiempo"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
                marginBottom: "15px",
              }}
            />
          </div>
        </div>

        <Button
          label="Buscar"
          icon="pi pi-search"
          onClick={buscarConsumos}
          style={{
            backgroundColor: "#007bff",
            borderColor: "#007bff",
            color: "#fff",
            padding: "10px 20px",
            fontSize: "14px",
            borderRadius: "4px",
            display: "block",
            margin: "auto",
          }}
        />
      </div>

      {isLoading && (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <ProgressSpinner />
        </div>
      )}

      {resultados.length > 0 && (
        <div
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
            marginBottom: "20px",
          }}
        >
          <h5
            style={{
              borderBottom: "2px solid #ced4da",
              paddingBottom: "10px",
              marginBottom: "20px",
              color: "#495057",
            }}
          >
            Resultados de Consumos
          </h5>

          <DataTable
            value={resultados}
            responsiveLayout="scroll"
            className="p-datatable-sm"
            paginator
            rows={10} // Número de filas por página
            rowsPerPageOptions={[5, 10, 20]} // Opciones de selección de filas por página
            stripedRows
            sortMode="multiple" // Permitir ordenar por múltiples columnas
          >
            <Column
              field="nombreCliente"
              header="Cliente"
              sortable
              filter
              filterPlaceholder="Buscar cliente"
              style={{ minWidth: "200px" }}
            />
            <Column
              field="domicilio"
              header="Domicilio"
              sortable
              filter
              filterPlaceholder="Buscar domicilio"
              style={{ minWidth: "200px" }}
            />
            {/*<Column
              field="idMedidor"
              header="ID Medidor"
              sortable
              filter
              filterPlaceholder="Buscar ID"
              style={{ minWidth: "150px" }}
            /> */}
            <Column
              field="tipoMedidor"
              header="Tipo Medidor"
              sortable
              filter
              filterField="tipoMedidor"
              filterMatchMode="contains"
              filterPlaceholder="Filtrar tipo"
              style={{ minWidth: "150px" }}
            />
            <Column
              field="fecha"
              header="Fecha Lectura"
              sortable
              filter
              filterMatchMode="date"
              style={{ minWidth: "150px" }}
            />
            <Column
              field="montoFinal"
              header="Monto Final"
              sortable
              filter
              filterMatchMode="gte"
              filterPlaceholder="Min monto"
              style={{ minWidth: "150px" }}
            />
            <Column
              field="consumo"
              header="Consumo"
              sortable
              filter
              filterMatchMode="gte"
              filterPlaceholder="Min consumo"
              style={{ minWidth: "150px" }}
            />
            <Column
              field="unidadMedida"
              header="Unidad de Medida"
              sortable
              filter
              filterPlaceholder="Filtrar unidad"
              style={{ minWidth: "150px" }}
            />
            <Column
              field="periodoMedicion"
              header="Periodo de Medición"
              sortable
              filter
              filterPlaceholder="Filtrar periodo"
              style={{ minWidth: "150px" }}
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};

export default Consumos;
