import React, { useState, useEffect, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { useGlobalContext } from "../Config";

const cardStyles = {
  imagen: {
    margin: "auto",
    width: "40%",
  },
};

const Login = ({
  colorMode,
  isNewThemeLoaded,
  onNewThemeChange,
  location,
  handleLogin,
  handleButtonClickRecuperar,
}) => {
  const { styleProperties } = useGlobalContext();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (e.nativeEvent.submitter.name === "loginButton") {
        e.preventDefault();
        //if (!captchaValue) {
        //alert("Por favor complete el CAPTCHA.");
        //return;
        //}
        handleLogin(email, password);
      }
    },
    [captchaValue, email, handleLogin, password]
  );

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        handleSubmit(e);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="pages-body login-page flex flex-column"
        style={{
          background: "none",
          backgroundColor: styleProperties.backgroundColor,
        }}
      >
        <div className="align-self-center mt-auto mb-auto">
          <div className="pages-panel card flex flex-column">
            <h1 className="pages-detail">{styleProperties.title}</h1>
            <img src={styleProperties.logo} alt="" style={cardStyles.imagen} />

            <h5 className="pages-detail mb-6 px-6">
              Bienvenido, inicia sesión para continuar
            </h5>

            <div className="input-panel flex flex-column px-3">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-envelope"></i>
                </span>
                <span className="p-float-label">
                  <InputText
                    autoComplete="off"
                    type="text"
                    id="inputgroup1"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <label htmlFor="inputgroup1">Correo Electrónico</label>
                </span>
              </div>

              <div className="p-inputgroup mt-3 mb-6">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-lock"></i>
                </span>
                <span className="p-float-label">
                  <InputText
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    id="inputgroup2"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <label htmlFor="inputgroup2">Contraseña</label>
                </span>
                <Button
                  className={showPassword ? "pi pi-eye" : "pi pi-eye-slash"}
                  onClick={toggleShowPassword}
                  tabIndex="-1"
                  style={{
                    backgroundColor: styleProperties.button.backgroundColor,
                    color: styleProperties.button.foreColor,
                  }}
                ></Button>
              </div>

              <div
                className="p-inputgroup mt-3 mb-6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* Add reCAPTCHA component 
                <ReCAPTCHA
                  sitekey="6Lf6hLYqAAAAAODdDrmWHJo7_9F6NzUK6RuAQKZa"
                  onChange={handleCaptchaChange}
                />*/}
              </div>
            </div>
            <Button
              type="submit"
              name="loginButton" // Add a name to the "Ingresar" button
              className="login-button mb-3 px-3"
              label="Ingresar"
              style={{ backgroundColor: "#007ad9" }}
            ></Button>
          </div>
        </div>
      </div>
    </form>
  );
};

/* <Button
  className="login-button mb-4 px-3"
  label="Registrarme"
  style={{
    backgroundColor: styleProperties.button.backgroundColor,
    color: styleProperties.button.foreColor,
    border: styleProperties.button.border,
  }}
></Button>

<Button
              className="login-button mb-3 px-3"
              label="Recuperar Contraseña"
              style={{
                backgroundColor: "white",
                color: "#6c757d",
                fontSize: "13px",
              }}
              onClick={handleButtonClickRecuperar}
            ></Button>
*/

export default Login;
